import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

const options = {
  fallbackLng: 'nl',

  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false,
  nsSeparator: false,

  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },

  react: {
    wait: true
  }
};

//
options.backend = {
  // loadPath: '/locales/{{lng}}/{{ns}}.json'
  loadPath: 'https://api.nutriportal.eu/locales/{{lng}}/{{ns}}.json',
  crossDomain: true
};
i18n.use(XHR);

export default() => {
  i18n.use(LanguageDetector).init(options);
  return i18n;
};
