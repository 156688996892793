import rqst_post from '../../lib/server_helper.js';

export default class AgendaOverride {
  static DESTROY = 'Coach.AgendaOverride.DESTROY';
  static DESTROY_BEGIN = 'Coach.AgendaOverride.DESTROY_BEGIN';
  static DESTROY_SUCCESS = 'Coach.AgendaOverride.DESTROY_SUCCESS';
  static DESTROY_ERROR = 'Coach.AgendaOverride.DESTROY_ERROR';

  static FETCH_ALL = 'Coach.AgendaOverride.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Coach.AgendaOverride.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Coach.AgendaOverride.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Coach.AgendaOverride.FETCH_ALL_ERROR';

  static SAVE = 'Coach.AgendaOverride.SAVE';
  static SAVE_BEGIN = 'Coach.AgendaOverride.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Coach.AgendaOverride.SAVE_SUCCESS';
  static SAVE_ERROR = 'Coach.AgendaOverride.SAVE_ERROR';

  static destroy = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/agenda_overrides/do_destroy`,
      begin: AgendaOverride.DESTROY_BEGIN,
      succes: AgendaOverride.DESTROY_SUCCESS,
      error: AgendaOverride.DESTROY_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/agenda_overrides/fetch_all`,
      begin: AgendaOverride.FETCH_ALL_BEGIN,
      succes: AgendaOverride.FETCH_ALL_SUCCESS,
      error: AgendaOverride.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/agenda_overrides/do_save`,
      begin: AgendaOverride.SAVE_BEGIN,
      succes: AgendaOverride.SAVE_SUCCESS,
      error: AgendaOverride.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case AgendaOverride.DESTROY:
        return {...state, loading: false, data: null};
      case AgendaOverride.DESTROY_BEGIN:
        return {...state, loading: true, error: null};
      case AgendaOverride.DESTROY_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case AgendaOverride.DESTROY_ERROR:
        return {...state, loading: false, error: action.payload};

      case AgendaOverride.FETCH_ALL:
        return {...state, loading: false, data: null};
      case AgendaOverride.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case AgendaOverride.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case AgendaOverride.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      case AgendaOverride.SAVE:
        return {...state, loading: false, save_data: null};
      case AgendaOverride.SAVE_BEGIN:
        return {...state, loading: true, save_error: null};
      case AgendaOverride.SAVE_SUCCESS:
        return {...state, loading: false, save_data: action.payload};
      case AgendaOverride.SAVE_ERROR:
        return {...state, loading: false, save_error: action.payload};

      default:
        return state;
    }
  }
}
