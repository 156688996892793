import rqst_post from '../../lib/server_helper.js';

export default class Appointment {
  static DESTROY = 'Coach.Appointment.DESTROY';
  static DESTROY_BEGIN = 'Coach.Appointment.DESTROY_BEGIN';
  static DESTROY_SUCCESS = 'Coach.Appointment.DESTROY_SUCCESS';
  static DESTROY_ERROR = 'Coach.Appointment.DESTROY_ERROR';

  static FETCH_ALL = 'Coach.Appointment.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Coach.Appointment.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Coach.Appointment.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Coach.Appointment.FETCH_ALL_ERROR';

  static SAVE = 'Coach.Appointment.SAVE';
  static SAVE_BEGIN = 'Coach.Appointment.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Coach.Appointment.SAVE_SUCCESS';
  static SAVE_ERROR = 'Coach.Appointment.SAVE_ERROR';

  static destroy = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/appointments/do_destroy`,
      begin: Appointment.DESTROY_BEGIN,
      succes: Appointment.DESTROY_SUCCESS,
      error: Appointment.DESTROY_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/appointments/fetch_all`,
      begin: Appointment.FETCH_ALL_BEGIN,
      succes: Appointment.FETCH_ALL_SUCCESS,
      error: Appointment.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/appointments/do_save`,
      begin: Appointment.SAVE_BEGIN,
      succes: Appointment.SAVE_SUCCESS,
      error: Appointment.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case Appointment.DESTROY:
        return {...state, loading: false, data: null};
      case Appointment.DESTROY_BEGIN:
        return {...state, loading: true, error: null};
      case Appointment.DESTROY_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Appointment.DESTROY_ERROR:
        return {...state, loading: false, error: action.payload};

      case Appointment.FETCH_ALL:
        return {...state, loading: false, data: null};
      case Appointment.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case Appointment.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Appointment.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload}

      case Appointment.SAVE:
        return {...state, loading: false, data: null};
      case Appointment.SAVE_BEGIN:
        return {...state, loading: true, error: null};
      case Appointment.SAVE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Appointment.SAVE_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
