import EmailTemplate from '../../actions/coach/email_template';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function email_template(state = initial, action) {
  return EmailTemplate.state_switch(state, action);
}
