import rqst_post from '../../lib/server_helper.js';

export default class MoveMoment {
  static SAVE = 'Coach.MoveMoment.SAVE';
  static SAVE_BEGIN = 'Coach.MoveMoment.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Coach.MoveMoment.SAVE_SUCCESS';
  static SAVE_ERROR = 'Coach.MoveMoment.SAVE_ERROR';

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/diary/move_moments/do_save`,
      begin: MoveMoment.SAVE_BEGIN,
      succes: MoveMoment.SAVE_SUCCESS,
      error: MoveMoment.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case MoveMoment.SAVE:
        return {...state, loading: false, data: null};
      case MoveMoment.SAVE_BEGIN:
        return {...state, loading: true, error: null};
      case MoveMoment.SAVE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case MoveMoment.SAVE_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
