import PracticeModule from '../actions/practice_module';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function practice_module(state = initial, action) {
  return PracticeModule.state_switch(state, action);
}
