import NewsItem from '../../actions/admin/news_item';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function news_item(state = initial, action) {
  return NewsItem.state_switch(state, action);
}
