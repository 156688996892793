import rqst_post from '../../lib/server_helper.js';

export default class MoveMoment {
  static SAVE = 'Client.MoveMoment.SAVE';
  static SAVE_BEGIN = 'Client.MoveMoment.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Client.MoveMoment.SAVE_SUCCESS';
  static SAVE_ERROR = 'Client.MoveMoment.SAVE_ERROR';

  static LOADERS = 'LOADERS';

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/diary/move_moments/do_save`,
      begin: MoveMoment.SAVE_BEGIN,
      succes: MoveMoment.SAVE_SUCCESS,
      error: MoveMoment.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static clear_loaders = () => {
    return (dispatch) => {
      dispatch({
        type: MoveMoment.LOADERS,
        payload: null
      });
    };
  };

  static state_switch = (state, action) => {
    switch (action.type) {
      case MoveMoment.SAVE:
        return {...state, loading: false, data: null};
      case MoveMoment.SAVE_BEGIN:
        return {...state, loading: true, error: null};
      case MoveMoment.SAVE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case MoveMoment.SAVE_ERROR:
        return {...state, loading: false, error: action.payload};

      case MoveMoment.LOADERS:
        return {...state,
          loading: false
        };

      default:
        return state;
    }
  }
}
