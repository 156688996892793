import rqst_post from '../../lib/server_helper.js';

export default class IntakeProfile {
  static FETCH_ALL = 'Admin.IntakeProfile.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Admin.IntakeProfile.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Admin.IntakeProfile.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Admin.IntakeProfile.FETCH_ALL_ERROR';

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/admin/intake_profiles/fetch_all`,
      begin: IntakeProfile.FETCH_ALL_BEGIN,
      succes: IntakeProfile.FETCH_ALL_SUCCESS,
      error: IntakeProfile.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case IntakeProfile.FETCH_ALL:
        return {...state, loading: false, data: null};
      case IntakeProfile.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case IntakeProfile.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case IntakeProfile.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
