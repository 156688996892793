import rqst_post from '../../lib/server_helper.js';

export default class Practice {
  static FETCH_ONE = 'Coach.Practice.FETCH_ONE';
  static FETCH_ONE_BEGIN = 'Coach.Practice.FETCH_ONE_BEGIN';
  static FETCH_ONE_SUCCESS = 'Coach.Practice.FETCH_ONE_SUCCESS';
  static FETCH_ONE_ERROR = 'Coach.Practice.FETCH_ONE_ERROR';

  static SAVE = 'Coach.Coach.SAVE';
  static SAVE_BEGIN = 'Coach.Coach.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Coach.Coach.SAVE_SUCCESS';
  static SAVE_ERROR = 'Coach.Coach.SAVE_ERROR';

  static fetch_one = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/practices/do_fetch`,
      begin: Practice.FETCH_ONE_BEGIN,
      succes: Practice.FETCH_ONE_SUCCESS,
      error: Practice.FETCH_ONE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/practices/do_update`,
      begin: Practice.SAVE_BEGIN,
      succes: Practice.SAVE_SUCCESS,
      error: Practice.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case Practice.FETCH_ONE:
        return {...state, loading: false, data: null};
      case Practice.FETCH_ONE_BEGIN:
        return {...state, loading: true, error: null};
      case Practice.FETCH_ONE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Practice.FETCH_ONE_ERROR:
        return {...state, loading: false, error: action.payload};

      case Practice.SAVE:
        return {...state, loading: false, save_data: null};
      case Practice.SAVE_BEGIN:
        return {...state, loading: true, save_error: null};
      case Practice.SAVE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Practice.SAVE_ERROR:
        return {...state, loading: false, save_error: action.payload};

      default:
        return state;
    }
  }
}
