import Wake from '../../actions/patient/wake';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function wake(state = initial, action) {
  return Wake.state_switch(state, action);
}
