import rqst_post from '../../lib/server_helper.js';

export default class User {
  static ASK_CONSENT = 'Coach.User.ASK_CONSENT';
  static ASK_CONSENT_BEGIN = 'Coach.User.ASK_CONSENT_BEGIN';
  static ASK_CONSENT_SUCCESS = 'Coach.User.ASK_CONSENT_SUCCESS';
  static ASK_CONSENT_ERROR = 'Coach.User.ASK_CONSENT_ERROR';

  static ASK_PASS_RESET = 'Coach.User.ASK_PASS_RESET';
  static ASK_PASS_RESET_BEGIN = 'Coach.User.ASK_PASS_RESET_BEGIN';
  static ASK_PASS_RESET_SUCCESS = 'Coach.User.ASK_PASS_RESET_SUCCESS';
  static ASK_PASS_RESET_ERROR = 'Coach.User.ASK_PASS_RESET_ERROR';

  static CHECK_EMAIL = 'Coach.User.CHECK_EMAIL';
  static CHECK_EMAIL_BEGIN = 'Coach.User.CHECK_EMAIL_BEGIN';
  static CHECK_EMAIL_SUCCESS = 'Coach.User.CHECK_EMAIL_SUCCESS';
  static CHECK_EMAIL_ERROR = 'Coach.User.CHECK_EMAIL_ERROR';

  static FETCH_ALL = 'Coach.User.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Coach.User.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Coach.User.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Coach.User.FETCH_ALL_ERROR';

  static FETCH_ALL_INDEX = 'Coach.User.FETCH_ALL_INDEX';
  static FETCH_ALL_INDEX_BEGIN = 'Coach.User.FETCH_ALL_INDEX_BEGIN';
  static FETCH_ALL_INDEX_SUCCESS = 'Coach.User.FETCH_ALL_INDEX_SUCCESS';
  static FETCH_ALL_INDEX_ERROR = 'Coach.User.FETCH_ALL_INDEX_ERROR';

  static FETCH_ALL_SELECT = 'Coach.User.FETCH_ALL_SELECT';
  static FETCH_ALL_SELECT_BEGIN = 'Coach.User.FETCH_ALL_SELECT_BEGIN';
  static FETCH_ALL_SELECT_SUCCESS = 'Coach.User.FETCH_ALL_SELECT_SUCCESS';
  static FETCH_ALL_SELECT_ERROR = 'Coach.User.FETCH_ALL_SELECT_ERROR';

  static FETCH_ALL_SHORT = 'Coach.User.FETCH_ALL_SHORT';
  static FETCH_ALL_SHORT_BEGIN = 'Coach.User.FETCH_ALL_SHORT_BEGIN';
  static FETCH_ALL_SHORT_SUCCESS = 'Coach.User.FETCH_ALL_SHORT_SUCCESS';
  static FETCH_ALL_SHORT_ERROR = 'Coach.User.FETCH_ALL_SHORT_ERROR';

  static SAVE = 'Coach.User.SAVE';
  static SAVE_BEGIN = 'Coach.User.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Coach.User.SAVE_SUCCESS';
  static SAVE_ERROR = 'Coach.User.SAVE_ERROR';

  static ask_consent = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/users/consent_inquiry`,
      begin: User.ASK_CONSENT_BEGIN,
      succes: User.ASK_CONSENT_SUCCESS,
      error: User.ASK_CONSENT_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static ask_pass_reset = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/users/password_reset_inquiry`,
      begin: User.ASK_PASS_RESET_BEGIN,
      succes: User.ASK_PASS_RESET_SUCCESS,
      error: User.ASK_PASS_RESET_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static check_email = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/users/check_email`,
      begin: User.CHECK_EMAIL_BEGIN,
      succes: User.CHECK_EMAIL_SUCCESS,
      error: User.CHECK_EMAIL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/users/fetch_all`,
      begin: User.FETCH_ALL_BEGIN,
      succes: User.FETCH_ALL_SUCCESS,
      error: User.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_all_index = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/users/fetch_all`,
      begin: User.FETCH_ALL_INDEX_BEGIN,
      succes: User.FETCH_ALL_INDEX_SUCCESS,
      error: User.FETCH_ALL_INDEX_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_all_select = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/users/fetch_all`,
      begin: User.FETCH_ALL_SHORT_BEGIN,
      succes: User.FETCH_ALL_SHORT_SUCCESS,
      error: User.FETCH_ALL_SHORT_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_all_short = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/users/fetch_all`,
      begin: User.FETCH_ALL_SHORT_BEGIN,
      succes: User.FETCH_ALL_SHORT_SUCCESS,
      error: User.FETCH_ALL_SHORT_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/users/do_save`,
      begin: User.SAVE_BEGIN,
      succes: User.SAVE_SUCCESS,
      error: User.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case User.ASK_CONSENT:
        return {...state, loading: false, consent_data: null};
      case User.ASK_CONSENT_BEGIN:
        return {...state, loading: true, consent_error: null};
      case User.ASK_CONSENT_SUCCESS:
        return {...state, loading: false, consent_data: action.payload};
      case User.ASK_CONSENT_ERROR:
        return {...state, loading: false, consent_error: action.payload};

      case User.ASK_PASS_RESET:
        return {...state, loading: false, pass_data: null};
      case User.ASK_PASS_RESET_BEGIN:
        return {...state, loading: true, pass_error: null};
      case User.ASK_PASS_RESET_SUCCESS:
        return {...state, loading: false, pass_data: action.payload};
      case User.ASK_PASS_RESET_ERROR:
        return {...state, loading: false, pass_error: action.payload};

      case User.CHECK_EMAIL:
        return {...state, loading: false, email_data: null};
      case User.CHECK_EMAIL_BEGIN:
        return {...state, loading: true, email_error: null};
      case User.CHECK_EMAIL_SUCCESS:
        return {...state, loading: false, email_data: action.payload};
      case User.CHECK_EMAIL_ERROR:
        return {...state, loading: false, email_error: action.payload};

      case User.FETCH_ALL:
        return {...state, loading: false, data: null};
      case User.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case User.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case User.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      case User.FETCH_ALL_INDEX:
        return {...state, index_loading: false, index_data: null};
      case User.FETCH_ALL_INDEX_BEGIN:
        return {...state, lindex_oading: true, index_error: null};
      case User.FETCH_ALL_INDEX_SUCCESS:
        return {...state, index_loading: false, index_data: action.payload};
      case User.FETCH_ALL_INDEX_ERROR:
        return {...state, index_loading: false, index_error: action.payload};

      case User.FETCH_ALL_SELECT:
        return {...state, select_loading: false, select_data: null};
      case User.FETCH_ALL_SELECT_BEGIN:
        return {...state, select_loading: true, select_error: null};
      case User.FETCH_ALL_SELECT_SUCCESS:
        return {...state, select_loading: false, select_data: action.payload};
      case User.FETCH_ALL_SELECT_ERROR:
        return {...state, select_loading: false, select_error: action.payload};

      case User.FETCH_ALL_SHORT:
        return {...state, short_loading: false, short_data: null};
      case User.FETCH_ALL_SHORT_BEGIN:
        return {...state, short_loading: true, short_error: null};
      case User.FETCH_ALL_SHORT_SUCCESS:
        return {...state, short_loading: false, short_data: action.payload};
      case User.FETCH_ALL_SHORT_ERROR:
        return {...state, short_loading: false, short_error: action.payload};

      case User.SAVE:
        return {...state, loading: false, save_data: null};
      case User.SAVE_BEGIN:
        return {...state, loading: true, save_error: null};
      case User.SAVE_SUCCESS:
        return {...state, loading: false, save_data: action.payload};
      case User.SAVE_ERROR:
        return {...state, loading: false, save_error: action.payload};

      default:
        return state;
    }
  }
}
