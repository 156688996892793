import Workshop from '../../actions/patient/workshop';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function workshop(state = initial, action) {
  return Workshop.state_switch(state, action);
}
