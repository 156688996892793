import rqst_post from '../../lib/server_helper.js';

export default class Practice {
  static FETCH = 'Client.Practice.FETCH';
  static FETCH_BEGIN = 'Client.Practice.FETCH_BEGIN';
  static FETCH_SUCCESS = 'Client.Practice.FETCH_SUCCESS';
  static FETCH_ERROR = 'Client.Practice.FETCH_ERROR';

  static FETCH_COACHES = 'Client.Practice.FETCH_COACHES';
  static FETCH_COACHES_BEGIN = 'Client.Practice.FETCH_COACHES_BEGIN';
  static FETCH_COACHES_SUCCESS = 'Client.Practice.FETCH_COACHES_SUCCESS';
  static FETCH_COACHES_ERROR = 'Client.Practice.FETCH_COACHES_ERROR';

  static FETCH_SUBDOMAIN = 'Client.Practice.FETCH_SUBDOMAIN';
  static FETCH_SUBDOMAIN_BEGIN = 'Client.Practice.FETCH_SUBDOMAIN_BEGIN';
  static FETCH_SUBDOMAIN_SUCCESS = 'Client.Practice.FETCH_SUBDOMAIN_SUCCESS';
  static FETCH_SUBDOMAIN_ERROR = 'Client.Practice.FETCH_SUBDOMAIN_ERROR';

  static FETCH_DETAIL = 'Client.Practice.FETCH_DETAIL';
  static FETCH_DETAIL_BEGIN = 'Client.Practice.FETCH_DETAIL_BEGIN';
  static FETCH_DETAIL_SUCCESS = 'Client.Practice.FETCH_DETAIL_SUCCESS';
  static FETCH_DETAIL_ERROR = 'Client.Practice.FETCH_DETAIL_ERROR';

  static LEAVE = 'Client.Practice.LEAVE';
  static LEAVE_BEGIN = 'Client.Practice.LEAVE_BEGIN';
  static LEAVE_SUCCESS = 'Client.Practice.LEAVE_SUCCESS';
  static LEAVE_ERROR = 'Client.Practice.LEAVE_ERROR';

  static PICK_PRACTICE = 'Client.Practice.PICK_PRACTICE';
  static PICK_PRACTICE_BEGIN = 'Client.Practice.PICK_PRACTICE_BEGIN';
  static PICK_PRACTICE_SUCCESS = 'Client.Practice.PICK_PRACTICE_SUCCESS';
  static PICK_PRACTICE_ERROR = 'Client.Practice.PICK_PRACTICE_ERROR';

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/practices/fetch_all`,
      begin: Practice.FETCH_BEGIN,
      succes: Practice.FETCH_SUCCESS,
      error: Practice.FETCH_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_coaches = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/practices/fetch_coaches`,
      begin: Practice.FETCH_COACHES_BEGIN,
      succes: Practice.FETCH_COACHES_SUCCESS,
      error: Practice.FETCH_COACHES_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_detail = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/practices/fetch_detail`,
      begin: Practice.FETCH_DETAIL_BEGIN,
      succes: Practice.FETCH_DETAIL_SUCCESS,
      error: Practice.FETCH_DETAIL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_subdomain = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/practices/fetch_for_subdomain`,
      begin: Practice.FETCH_SUBDOMAIN_BEGIN,
      succes: Practice.FETCH_SUBDOMAIN_SUCCESS,
      error: Practice.FETCH_SUBDOMAIN_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static leave = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/practices/leave`,
      begin: Practice.PICK_PRACTICE_BEGIN,
      succes: "Devise.LOGIN_SUCCESS",
      error: Practice.PICK_PRACTICE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static pick_practice = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/practices/pick_practice`,
      begin: Practice.PICK_PRACTICE_BEGIN,
      succes: "Devise.LOGIN_SUCCESS",
      error: Practice.PICK_PRACTICE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case Practice.FETCH:
        return {...state, loading: false, data: null};
      case Practice.FETCH_BEGIN:
        return {...state, loading: true, error: null};
      case Practice.FETCH_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Practice.FETCH_ERROR:
        return {...state, loading: false, error: action.payload};

      case Practice.FETCH_COACHES:
        return {...state, loading: false, coaches_data: null};
      case Practice.FETCH_COACHES_BEGIN:
        return {...state, loading: true, coaches_error: null};
      case Practice.FETCH_COACHES_SUCCESS:
        return {...state, loading: false, coaches_data: action.payload};
      case Practice.FETCH_COACHES_ERROR:
        return {...state, loading: false, coaches_error: action.payload};

      case Practice.FETCH_SUBDOMAIN:
        return {...state, loading: false, subdomain_data: null};
      case Practice.FETCH_SUBDOMAIN_BEGIN:
        return {...state, loading: true, subdomain_error: null};
      case Practice.FETCH_SUBDOMAIN_SUCCESS:
        return {...state, loading: false, subdomain_data: action.payload};
      case Practice.FETCH_SUBDOMAIN_ERROR:
        return {...state, loading: false, subdomain_error: action.payload};

      case Practice.FETCH_DETAIL:
        return {...state, loading: false, detail_data: null};
      case Practice.FETCH_DETAIL_BEGIN:
        return {...state, loading: true, detail_error: null};
      case Practice.FETCH_DETAIL_SUCCESS:
        return {...state, loading: false, detail_data: action.payload};
      case Practice.FETCH_DETAIL_ERROR:
        return {...state, loading: false, detail_error: action.payload};

      case Practice.LEAVE:
        return {...state, loading: false, pick_data: null};
      case Practice.LEAVE_BEGIN:
        return {...state, loading: true, pick_error: null};
      case Practice.LEAVE_SUCCESS:
        return {...state, loading: false, pick_data: action.payload};
      case Practice.LEAVE_ERROR:
        return {...state, loading: false, pick_error: action.payload};

      case Practice.PICK_PRACTICE:
        return {...state, loading: false, pick_data: null};
      case Practice.PICK_PRACTICE_BEGIN:
        return {...state, loading: true, pick_error: null};
      case Practice.PICK_PRACTICE_SUCCESS:
        return {...state, loading: false, pick_data: action.payload};
      case Practice.PICK_PRACTICE_ERROR:
        return {...state, loading: false, pick_error: action.payload};

      default:
        return state;
    }
  }
}
