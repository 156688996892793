import CoachExport from '../../actions/coach/export';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function coach_export(state = initial, action) {
  return CoachExport.state_switch(state, action);
}
