import MergeRequest from '../../actions/coach/merge_request';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function merge_request(state = initial, action) {
  return MergeRequest.state_switch(state, action);
}
