import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Devise from '../actions/devise';
import CoachPractice from '../actions/coach/practice';
import Country from '../actions/country';
import PatientPractice from '../actions/patient/practice';
import Main from '../components/Main';

const map_state_to_props = state => ({
  login: state.devise.data,
  subdomain_practice: state.patient_practice.subdomain_data
  // ,
  // countries: state.country.data,
  // country_loading: state.country.loading,
  // country_error: state.country.error
})

const map_dispatch_to_props = dispatch => {
  return {
    // checkLogin: _params => {
    //   dispatch(checkLogin(_params));
    // },
    // checkPush: _params => {
    //   dispatch(checkPush(_params));
    // },
    onLogout: _params => {
      dispatch(Devise.sign_out());
    },
    helpers: {
      countries: {
        fetch_all: (_params) => {
          dispatch(Country.fetch_all(_params));
        }
      },
      practices: {
        fetch_one: (_params) => {
          dispatch(CoachPractice.fetch_one(_params));
        },
        fetch_subdomain: (_params) => {
          dispatch(PatientPractice.fetch_subdomain(_params));
        }
      }
    }
  };
};

export default withRouter(connect(
  map_state_to_props,
  map_dispatch_to_props
)(Main));
