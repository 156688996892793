import rqst_post from '../../lib/server_helper.js';

export default class DefaultAgenda {
  static DESTROY = 'Coach.DefaultAgenda.DESTROY';
  static DESTROY_BEGIN = 'Coach.DefaultAgenda.DESTROY_BEGIN';
  static DESTROY_SUCCESS = 'Coach.DefaultAgenda.DESTROY_SUCCESS';
  static DESTROY_ERROR = 'Coach.DefaultAgenda.DESTROY_ERROR';

  static FETCH_ALL = 'Coach.DefaultAgenda.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Coach.DefaultAgenda.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Coach.DefaultAgenda.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Coach.DefaultAgenda.FETCH_ALL_ERROR';

  static LOADERS = 'LOADERS';

  static SAVE = 'Coach.DefaultAgenda.SAVE';
  static SAVE_BEGIN = 'Coach.DefaultAgenda.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Coach.DefaultAgenda.SAVE_SUCCESS';
  static SAVE_ERROR = 'Coach.DefaultAgenda.SAVE_ERROR';

  static SAVE_LAYOUT = 'Coach.DefaultAgenda.SAVE_LAYOUT';
  static SAVE_LAYOUT_BEGIN = 'Coach.DefaultAgenda.SAVE_LAYOUT_BEGIN';
  static SAVE_LAYOUT_SUCCESS = 'Coach.DefaultAgenda.SAVE_LAYOUT_SUCCESS';
  static SAVE_LAYOUT_ERROR = 'Coach.DefaultAgenda.SAVE_LAYOUT_ERROR';

  static clear_loaders = () => {
    return (dispatch) => {
      dispatch({
        type: DefaultAgenda.LOADERS,
        payload: null
      });
    };
  };

  static destroy = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/defaults/do_destroy`,
      begin: DefaultAgenda.DESTROY_BEGIN,
      succes: DefaultAgenda.DESTROY_SUCCESS,
      error: DefaultAgenda.DESTROY_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/defaults/fetch_default_layout`,
      begin: DefaultAgenda.FETCH_ALL_BEGIN,
      succes: DefaultAgenda.FETCH_ALL_SUCCESS,
      error: DefaultAgenda.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/defaults/do_save`,
      begin: DefaultAgenda.SAVE_BEGIN,
      succes: DefaultAgenda.SAVE_SUCCESS,
      error: DefaultAgenda.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save_layout = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/defaults/do_save_layout`,
      begin: DefaultAgenda.SAVE_LAYOUT_BEGIN,
      succes: DefaultAgenda.SAVE_LAYOUT_SUCCESS,
      error: DefaultAgenda.SAVE_LAYOUT_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case DefaultAgenda.DESTROY:
        return {...state, loading: false, data: null};
      case DefaultAgenda.DESTROY_BEGIN:
        return {...state, loading: true, error: null};
      case DefaultAgenda.DESTROY_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case DefaultAgenda.DESTROY_ERROR:
        return {...state, loading: false, error: action.payload};

      case DefaultAgenda.FETCH_ALL:
        return {...state, loading: false, data: null};
      case DefaultAgenda.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case DefaultAgenda.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case DefaultAgenda.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      case DefaultAgenda.LOADERS:
        return {...state,
          save_layout_throw: false, save_layout_error: null, save_layout_data: null, save_error: null,
          save_data: false
        };

      case DefaultAgenda.SAVE:
        return {...state, loading: false, save_data: null};
      case DefaultAgenda.SAVE_BEGIN:
        return {...state, loading: true, save_error: null};
      case DefaultAgenda.SAVE_SUCCESS:
        return {...state, loading: false, save_data: action.payload};
      case DefaultAgenda.SAVE_ERROR:
        return {...state, loading: false, save_error: action.payload};

      case DefaultAgenda.SAVE_LAYOUT:
        return {...state, loading: false, save_layout_data: null};
      case DefaultAgenda.SAVE_LAYOUT_BEGIN:
        return {...state, loading: true, save_layout_error: null};
      case DefaultAgenda.SAVE_LAYOUT_SUCCESS:
        return {...state, loading: false, save_layout_data: action.payload};
      case DefaultAgenda.SAVE_LAYOUT_ERROR:
        return {...state, loading: false, save_layout_error: action.payload, save_layout_throw: action.data_payload};

      default:
        return state;
    }
  }
}
