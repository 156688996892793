import rqst_post from '../../lib/server_helper.js';

export default class Coach {
  static FETCH_ALL = 'Admin.Coach.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Admin.Coach.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Admin.Coach.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Admin.Coach.FETCH_ALL_ERROR';

  static REQUEST_IMPERSONATE_URL = 'Admin.Coach.REQUEST_IMPERSONATE_URL';
  static REQUEST_IMPERSONATE_URL_BEGIN = 'Admin.Coach.REQUEST_IMPERSONATE_URL_BEGIN';
  static REQUEST_IMPERSONATE_URL_SUCCESS = 'Admin.Coach.REQUEST_IMPERSONATE_URL_SUCCESS';
  static REQUEST_IMPERSONATE_URL_ERROR = 'Admin.Coach.REQUEST_IMPERSONATE_URL_ERROR';

  static SAVE = 'Admin.Coach.SAVE';
  static SAVE_BEGIN = 'Admin.Coach.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Admin.Coach.SAVE_SUCCESS';
  static SAVE_ERROR = 'Admin.Coach.SAVE_ERROR';

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/admin/coaches/fetch_all`,
      begin: Coach.FETCH_ALL_BEGIN,
      succes: Coach.FETCH_ALL_SUCCESS,
      error: Coach.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static request_impersonate_url = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/admin/coaches/request_impersonate_url`,
      begin: Coach.REQUEST_IMPERSONATE_URL_BEGIN,
      succes: Coach.REQUEST_IMPERSONATE_URL_SUCCESS,
      error: Coach.REQUEST_IMPERSONATE_URL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/admin/coaches/do_save`,
      begin: Coach.SAVE_BEGIN,
      succes: Coach.SAVE_SUCCESS,
      error: Coach.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case Coach.FETCH_ALL:
        return {...state, loading: false, data: null};
      case Coach.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case Coach.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Coach.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      case Coach.REQUEST_IMPERSONATE_URL:
        return {...state, loading: false, imp_data: null};
      case Coach.REQUEST_IMPERSONATE_URL_BEGIN:
        return {...state, loading: true, imp_error: null};
      case Coach.REQUEST_IMPERSONATE_URL_SUCCESS:
        return {...state, loading: false, imp_data: action.payload};
      case Coach.REQUEST_IMPERSONATE_URL_ERROR:
        return {...state, loading: false, imp_error: action.payload};

      case Coach.SAVE:
        return {...state, loading: false, data: null};
      case Coach.SAVE_BEGIN:
        return {...state, loading: true, error: null};
      case Coach.SAVE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Coach.SAVE_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
