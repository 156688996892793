import NutriMail from '../../actions/coach/nutri_mail';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function nutri_mail(state = initial, action) {
  return NutriMail.state_switch(state, action);
}
