import server from '../lib/server.js'

export default function rqst_post({
  url, begin, succes, error,
  locale, parameters = {}, login, callback, error_callback,
  ignore_login, ignore_succes
}) {
  return (dispatch) => {
    let h = {};
    if (typeof(login) !== 'undefined' && login !== null) {
      h = {
        "Auth-Token": (login.auth_token || (login.data ? login.data.auth_token : '')),
        "Auth-Email": (login.attributes || login.data.attributes).email
      };
    }

    dispatch({
      type: begin
    });
    return server.post(url, parameters, {
      headers: h
    }).then(_response => {
      var _data = _response.data;
      window.reported_version = _data.version;
      if (_data.success) {
        let r = _data.data;
        if (typeof(r.result) !== 'undefined') r = r.result;
        if (ignore_login) {
          dispatch({type: ignore_succes, payload: r});
        } else {
          dispatch({type: succes, payload: r});
        }

        if (callback !== undefined) callback(r, _data);
      } else {
        dispatch({type: error, payload: "no_success", data_payload: _data.data});

        if (error_callback !== undefined) error_callback(_data.data);
      }
    }).catch(_error => {
      if (_error.response && _error.response.data && _error.response.data.data === "wrong_authentication_level") {
        dispatch({type: error, payload: "http_error"});
      } else if (_error.response && _error.response.data && _error.response.data.data === "not_authenticated") {
        dispatch({type: 'Devise.LOGIN', payload: null});
      } else if (_error.response && _error.response.data && _error.response.data.data === "Disabled user") {
        dispatch({type: 'Devise.LOGIN_ERROR', payload: "no_success", data_payload: "disabled"});
      } else if (_error.response && _error.response.data && _error.response.data.data === "Unconfirmed user") {
        dispatch({type: 'Devise.LOGIN_ERROR', payload: "no_success", data_payload: "unconfirmed"});
      } else {
        dispatch({type: error, payload: "http_error"});
      }

      if (error_callback !== undefined) error_callback(_error.response.data);
    });
  };
}
