import { combineReducers } from 'redux';

import admin_coach from './admin/coach';
import admin_email_template from './admin/email_template';
import admin_intake_profile from './admin/intake_profile';
import admin_merge_request from './admin/merge_request';
import admin_news_item from './admin/news_item';
import admin_sms_payment from './admin/sms_payment';
import admin_stats from './admin/stats';

import coach_advice from './coach/advice';
import coach_agenda from './coach/agenda';
import coach_agenda_override from './coach/agenda_override';
import coach_appointment from './coach/appointment';
import coach_appointment_type from './coach/appointment_type';
import coach_coach_skill from './coach/coach_skill';
import coach_day_note from './coach/day_note';
import coach_default_agenda from './coach/default_agenda';
import coach_diary from './coach/diary';
import coach_eat_moment from './coach/eat_moment';
import coach_ehealth_mail from './coach/ehealth_mail';
import coach_email_template from './coach/email_template';
import coach_export from './coach/export';
import coach_intake_profile from './coach/intake_profile';
import coach_log from './coach/log';
import coach_merge_request from './coach/merge_request';
import coach_move_moment from './coach/move_moment';
import coach_news_item from './coach/news_item';
import coach_nutri_mail from './coach/nutri_mail';
import coach_practice from './coach/practice';
import coach_stats from './coach/stats';
import coach_treatment_plan from './coach/treatment_plan';
import coach_user from './coach/user';
import coach_weight from './coach/weight';
import coach_workshop from './coach/workshop';

import patient_advice from './patient/advice';
import patient_agenda from './patient/agenda';
import patient_appointment from './patient/appointment';
import patient_appointment_type from './patient/appointment_type';
import patient_coach from './patient/coach';
import patient_consent from './patient/consent';
import patient_diary from './patient/diary';
import patient_eat_moment from './patient/eat_moment';
import patient_intake_profile from './patient/intake_profile';
import patient_move_moment from './patient/move_moment';
import patient_news_item from './patient/news_item';
import patient_nutri_mail from './patient/nutri_mail';
import patient_practice from './patient/practice';
import patient_treatment_plan from './patient/treatment_plan';
import patient_wake from './patient/wake';
import patient_weight from './patient/weight';
import patient_workshop from './patient/workshop';

import coach_skill from './coach_skill';
import country from './country';
import devise from './devise';
import location from './location';
import practice from './practice';
import practice_module from './practice_module';
import practice_type from './practice_type';

const drawer = (state = {
  open: false
}, action) => {
  switch (action.type) {
    case 'DRAWER':
      return {...state, ...action.payload};
    default:
      return state
  }
};

export default combineReducers({
  drawer,

  admin_coach,
  admin_email_template,
  admin_intake_profile,
  admin_merge_request,
  admin_news_item,
  admin_sms_payment,
  admin_stats,

  coach_advice,
  coach_agenda,
  coach_agenda_override,
  coach_appointment,
  coach_appointment_type,
  coach_coach_skill,
  coach_day_note,
  coach_default_agenda,
  coach_diary,
  coach_eat_moment,
  coach_ehealth_mail,
  coach_email_template,
  coach_export,
  coach_intake_profile,
  coach_log,
  coach_merge_request,
  coach_move_moment,
  coach_news_item,
  coach_nutri_mail,
  coach_practice,
  coach_stats,
  coach_treatment_plan,
  coach_user,
  coach_weight,
  coach_workshop,

  patient_advice,
  patient_agenda,
  patient_appointment,
  patient_appointment_type,
  patient_coach,
  patient_consent,
  patient_diary,
  patient_eat_moment,
  patient_intake_profile,
  patient_move_moment,
  patient_news_item,
  patient_nutri_mail,
  patient_practice,
  patient_treatment_plan,
  patient_workshop,
  patient_wake,
  patient_weight,

  coach_skill,
  country,
  devise,
  location,
  practice,
  practice_module,
  practice_type
});
