import Practice from '../../actions/coach/practice';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function practice(state = initial, action) {
  return Practice.state_switch(state, action);
}
