import rqst_post from '../../lib/server_helper.js';

export default class Agenda {
  static FETCH_ALL_APP = 'Client.Agenda.FETCH_ALL_APP';
  static FETCH_ALL_APP_BEGIN = 'Client.Agenda.FETCH_ALL_APP_BEGIN';
  static FETCH_ALL_APP_SUCCESS = 'Client.Agenda.FETCH_ALL_APP_SUCCESS';
  static FETCH_ALL_APP_ERROR = 'Client.Agenda.FETCH_ALL_APP_ERROR';

  static FETCH_ALL = 'Client.Agenda.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Client.Agenda.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Client.Agenda.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Client.Agenda.FETCH_ALL_ERROR';

  static fetch_all_appointments = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/nutri_calendar/agenda/fetch_all`,
      begin: Agenda.FETCH_ALL_APP_BEGIN,
      succes: Agenda.FETCH_ALL_APP_SUCCESS,
      error: Agenda.FETCH_ALL_APP_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/nutri_calendar/agenda/fetch_init`,
      begin: Agenda.FETCH_ALL_BEGIN,
      succes: Agenda.FETCH_ALL_SUCCESS,
      error: Agenda.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case Agenda.FETCH_ALL_APP:
        return {...state, loading: false, app_data: null};
      case Agenda.FETCH_ALL_APP_BEGIN:
        return {...state, loading: true, app_error: null};
      case Agenda.FETCH_ALL_APP_SUCCESS:
        return {...state, loading: false, app_data: action.payload};
      case Agenda.FETCH_ALL_APP_ERROR:
        return {...state, loading: false, app_error: action.payload};

      case Agenda.FETCH_ALL:
        return {...state, loading: false, data: null};
      case Agenda.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case Agenda.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Agenda.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
