import Country from '../actions/country';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function country(state = initial, action) {
  return Country.state_switch(state, action);
}
