import MoveMoment from '../../actions/coach/move_moment';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function move_moment(state = initial, action) {
  return MoveMoment.state_switch(state, action);
}
