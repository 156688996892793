import AdminStats from '../../actions/admin/stats';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function admin_stats(state = initial, action) {
  return AdminStats.state_switch(state, action);
}
