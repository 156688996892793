import rqst_post from '../../lib/server_helper.js';

export default class Agenda {
  static DESTROY = 'Coach.Agenda.DESTROY';
  static DESTROY_BEGIN = 'Coach.Agenda.DESTROY_BEGIN';
  static DESTROY_SUCCESS = 'Coach.Agenda.DESTROY_SUCCESS';
  static DESTROY_ERROR = 'Coach.Agenda.DESTROY_ERROR';

  static FETCH_ALL_APP = 'Coach.Agenda.FETCH_ALL_APP';
  static FETCH_ALL_APP_BEGIN = 'Coach.Agenda.FETCH_ALL_APP_BEGIN';
  static FETCH_ALL_APP_SUCCESS = 'Coach.Agenda.FETCH_ALL_APP_SUCCESS';
  static FETCH_ALL_APP_ERROR = 'Coach.Agenda.FETCH_ALL_APP_ERROR';

  static FETCH_ALL = 'Coach.Agenda.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Coach.Agenda.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Coach.Agenda.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Coach.Agenda.FETCH_ALL_ERROR';

  static SAVE = 'Coach.Agenda.SAVE';
  static SAVE_BEGIN = 'Coach.Agenda.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Coach.Agenda.SAVE_SUCCESS';
  static SAVE_ERROR = 'Coach.Agenda.SAVE_ERROR';

  static SAVE_REC = 'Coach.Agenda.SAVE_REC';
  static SAVE_REC_BEGIN = 'Coach.Agenda.SAVE_REC_BEGIN';
  static SAVE_REC_SUCCESS = 'Coach.Agenda.SAVE_REC_SUCCESS';
  static SAVE_REC_ERROR = 'Coach.Agenda.SAVE_REC_ERROR';

  static destroy = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/agenda/do_destroy`,
      begin: Agenda.DESTROY_BEGIN,
      succes: Agenda.DESTROY_SUCCESS,
      error: Agenda.DESTROY_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_all_appointments = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/agenda/fetch_all`,
      begin: Agenda.FETCH_ALL_APP_BEGIN,
      succes: Agenda.FETCH_ALL_APP_SUCCESS,
      error: Agenda.FETCH_ALL_APP_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/agenda/fetch_init`,
      begin: Agenda.FETCH_ALL_BEGIN,
      succes: Agenda.FETCH_ALL_SUCCESS,
      error: Agenda.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/agenda/save_appointment`,
      begin: Agenda.SAVE_BEGIN,
      succes: Agenda.SAVE_SUCCESS,
      error: Agenda.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save_recurrence = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_calendar/agenda/save_recurrence`,
      begin: Agenda.SAVE_REC_BEGIN,
      succes: Agenda.SAVE_REC_SUCCESS,
      error: Agenda.SAVE_REC_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case Agenda.DESTROY:
        return {...state, loading: false, data: null};
      case Agenda.DESTROY_BEGIN:
        return {...state, loading: true, error: null};
      case Agenda.DESTROY_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Agenda.DESTROY_ERROR:
        return {...state, loading: false, error: action.payload};

      case Agenda.FETCH_ALL_APP:
        return {...state, loading: false, app_data: null};
      case Agenda.FETCH_ALL_APP_BEGIN:
        return {...state, loading: true, app_error: null};
      case Agenda.FETCH_ALL_APP_SUCCESS:
        return {...state, loading: false, app_data: action.payload};
      case Agenda.FETCH_ALL_APP_ERROR:
        return {...state, loading: false, app_error: action.payload};

      case Agenda.FETCH_ALL:
        return {...state, loading: false, data: null};
      case Agenda.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case Agenda.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Agenda.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      case Agenda.SAVE:
        return {...state, loading: false, save_data: null};
      case Agenda.SAVE_BEGIN:
        return {...state, loading: true, save_error: null};
      case Agenda.SAVE_SUCCESS:
        return {...state, loading: false, save_data: action.payload};
      case Agenda.SAVE_ERROR:
        return {...state, loading: false, save_error: action.payload};

      case Agenda.SAVE_REC:
        return {...state, loading: false, save_rec_data: null};
      case Agenda.SAVE_REC_BEGIN:
        return {...state, loading: true, save_rec_error: null};
      case Agenda.SAVE_REC_SUCCESS:
        return {...state, loading: false, save_rec_data: action.payload};
      case Agenda.SAVE_REC_ERROR:
        return {...state, loading: false, save_rec_error: action.payload};

      default:
        return state;
    }
  }
}
