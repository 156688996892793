import PracticeType from '../actions/practice_type';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function practice_type(state = initial, action) {
  return PracticeType.state_switch(state, action);
}
