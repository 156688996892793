import rqst_post from '../../lib/server_helper.js';

export default class Workshop {
  static FETCH_ALL = 'Client.Workshop.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Client.Workshop.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Client.Workshop.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Client.Workshop.FETCH_ALL_ERROR';

  static SUBSCRIBE = 'Client.Workshop.SUBSCRIBE';
  static SUBSCRIBE_BEGIN = 'Client.Workshop.SUBSCRIBE_BEGIN';
  static SUBSCRIBE_SUCCESS = 'Client.Workshop.SUBSCRIBE_SUCCESS';
  static SUBSCRIBE_ERROR = 'Client.Workshop.SUBSCRIBE_ERROR';

  static UNSUBSCRIBE = 'Client.Workshop.UNSUBSCRIBE';
  static UNSUBSCRIBE_BEGIN = 'Client.Workshop.UNSUBSCRIBE_BEGIN';
  static UNSUBSCRIBE_SUCCESS = 'Client.Workshop.UNSUBSCRIBE_SUCCESS';
  static UNSUBSCRIBE_ERROR = 'Client.Workshop.UNSUBSCRIBE_ERROR';

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/nutri_workshop/workshops/fetch_all`,
      begin: Workshop.FETCH_ALL_BEGIN,
      succes: Workshop.FETCH_ALL_SUCCESS,
      error: Workshop.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static subscribe = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/nutri_workshop/workshops/subscribe`,
      begin: Workshop.SUBSCRIBE_BEGIN,
      succes: Workshop.SUBSCRIBE_SUCCESS,
      error: Workshop.SUBSCRIBE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static unsubscribe = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/nutri_workshop/workshops/unsubscribe`,
      begin: Workshop.UNSUBSCRIBE_BEGIN,
      succes: Workshop.UNSUBSCRIBE_SUCCESS,
      error: Workshop.UNSUBSCRIBE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case Workshop.FETCH_ALL:
        return {...state, loading: false, data: null};
      case Workshop.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case Workshop.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Workshop.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      case Workshop.SUBSCRIBE:
        return {...state, loading: false, data: null};
      case Workshop.SUBSCRIBE_BEGIN:
        return {...state, loading: true, error: null};
      case Workshop.SUBSCRIBE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Workshop.SUBSCRIBE_ERROR:
        return {...state, loading: false, error: action.payload};

      case Workshop.UNSUBSCRIBE:
        return {...state, loading: false, data: null};
      case Workshop.UNSUBSCRIBE_BEGIN:
        return {...state, loading: true, error: null};
      case Workshop.UNSUBSCRIBE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Workshop.UNSUBSCRIBE_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
