import TreatmentPlan from '../../actions/coach/treatment_plan';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function treatment_plan(state = initial, action) {
  return TreatmentPlan.state_switch(state, action);
}
