import IntakeProfile from '../../actions/patient/intake_profile';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function intake_profile(state = initial, action) {
  return IntakeProfile.state_switch(state, action);
}
