import rqst_post from '../lib/server_helper.js';
import rqst_password_post from '../lib/server_helper_password.js';
import rqst_sign_out from '../lib/server_helper_sign_out.js';
import rqst_signed_in from '../lib/server_helper_signed_in.js';

export default class Devise {
  static CHECK = 'Devise.CHECK';
  static CHECK_BEGIN = 'Devise.CHECK_BEGIN';
  static CHECK_SUCCESS = 'Devise.CHECK_SUCCESS';
  static CHECK_ERROR = 'Devise.CHECK_ERROR';

  static CLOSE = 'Devise.CLOSE';
  static CLOSE_BEGIN = 'Devise.CLOSE_BEGIN';
  static CLOSE_SUCCESS = 'Devise.CLOSE_SUCCESS';
  static CLOSE_ERROR = 'Devise.CLOSE_ERROR';

  static CONFIRM = 'Devise.CONFIRM';
  static CONFIRM_BEGIN = 'Devise.CONFIRM_BEGIN';
  static CONFIRM_SUCCESS = 'Devise.CONFIRM_SUCCESS';
  static CONFIRM_ERROR = 'Devise.CONFIRM_ERROR';

  static PASSWORD = 'Devise.PASSWORD';
  static PASSWORD_BEGIN = 'Devise.PASSWORD_BEGIN';
  static PASSWORD_SUCCESS = 'Devise.PASSWORD_SUCCESS';
  static PASSWORD_ERROR = 'Devise.PASSWORD_ERROR';

  static LOADERS = 'LOADERS';

  static LOGIN = 'Devise.LOGIN';
  static LOGIN_BEGIN = 'Devise.LOGIN_BEGIN';
  static LOGIN_SUCCESS = 'Devise.LOGIN_SUCCESS';
  static LOGIN_ERROR = 'Devise.LOGIN_ERROR';

  static REGISTER = 'Devise.REGISTER';
  static REGISTER_BEGIN = 'Devise.REGISTER_BEGIN';
  static REGISTER_SUCCESS = 'Devise.REGISTER_SUCCESS';
  static REGISTER_ERROR = 'Devise.REGISTER_ERROR';

  static SAVE = 'Devise.SAVE';
  static SAVE_BEGIN = 'Devise.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Devise.SAVE_SUCCESS';
  static SAVE_ERROR = 'Devise.SAVE_ERROR';

  static VALIDATE = 'Devise.VALIDATE';
  static VALIDATE_BEGIN = 'Devise.VALIDATE_BEGIN';
  static VALIDATE_SUCCESS = 'Devise.VALIDATE_SUCCESS';
  static VALIDATE_ERROR = 'Devise.VALIDATE_ERROR';

  static clear_loaders = () => {
    return (dispatch) => {
      dispatch({
        type: Devise.LOADERS,
        payload: null
      });
    };
  };

  static check_email = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/users/check_email`,
      begin: Devise.CHECK_BEGIN,
      succes: Devise.CHECK_SUCCESS,
      error: Devise.CHECK_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static close_account = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/users/close_account`,
      begin: Devise.CLOSE_BEGIN,
      succes: Devise.CLOSE_SUCCESS,
      error: Devise.CLOSE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static confirm = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/users/do_confirm`,
      begin: Devise.CONFIRM_BEGIN,
      succes: Devise.LOGIN_SUCCESS,
      error: Devise.CONFIRM_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static edit_password = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_password_post({
      url: `/${locale}/users/edit_password`,
      begin: Devise.PASSWORD_BEGIN,
      succes: Devise.PASSWORD_SUCCESS,
      error: Devise.PASSWORD_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static forgot_password = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_password_post({
      url: `/${locale}/users/forgot_password`,
      begin: Devise.PASSWORD_BEGIN,
      succes: Devise.PASSWORD_SUCCESS,
      error: Devise.PASSWORD_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static renew_sign_in = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/users/renew_sign_in`,
      begin: Devise.LOGIN_BEGIN,
      succes: Devise.LOGIN_SUCCESS,
      error: Devise.LOGIN_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save = ({
    locale, parameters = {}, login, callback, ignore_login
  }) => {
    return rqst_post({
      url: `/${locale}/users/update`,
      begin: Devise.SAVE_BEGIN,
      succes: Devise.LOGIN_SUCCESS,
      ignore_succes: Devise.SAVE_SUCCESS,
      error: Devise.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static sign_in = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/users/login`,
      begin: Devise.LOGIN_BEGIN,
      succes: Devise.LOGIN_SUCCESS,
      error: Devise.LOGIN_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static sign_out = ({
    locale, login, callback
  }) => {
    return rqst_sign_out({
      url: `/${locale}/users/logout`,
      signout: Devise.LOGIN,
      locale: locale,
      login: login,
      callback: callback
    });
  };

  static sign_up = ({
    locale, parameters = {}, login, callback, error_callback
  }) => {
    return rqst_post({
      url: `/${locale}/users/register`,
      begin: Devise.REGISTER_BEGIN,
      succes: Devise.REGISTER_SUCCESS,
      error: Devise.REGISTER_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback,
      error_callback: error_callback
    });
  };

  static signed_in = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_signed_in({
      url: `/${locale}/users/signed_in`,
      signout: Devise.LOGIN,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static validate = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/users/login`,
      begin: Devise.VALIDATE_BEGIN,
      succes: Devise.VALIDATE_SUCCESS,
      error: Devise.VALIDATE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // export const checkPush = ({
  //   locale,
  //   login,
  //   subscription,
  //   message
  // }) => {
  //   return (dispatch) => {
  //     if (login) {
  //       return server.post(`/${locale}/users/do_push_register`, {
  //         push: subscription
  //       }, {
  //         headers: {
  //           'Auth-Token': login.auth_token,
  //           'Auth-Email': login.attributes.email
  //         }
  //       }).then(_response => {
  //         var _data = _response.data;
  //         if (_data.success) {
  //           server.post(`/users/push_test`, {
  //             message
  //           }, {
  //             headers: {
  //               'Auth-Token': login.auth_token,
  //               'Auth-Email': login.attributes.email
  //             }
  //           }).then(_response => {
  //             console.log("push registration gedaan")
  //           });
  //         }
  //       });
  //     }
  //   };
  // }

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case Devise.CHECK:
        return {...state, check_loading: false, check_data: null};
      case Devise.CHECK_BEGIN:
        return {...state, check_loading: true, check_error: null};
      case Devise.CHECK_SUCCESS:
        return {...state, check_loading: false, check_data: action.payload};
      case Devise.CHECK_ERROR:
        return {...state, check_loading: false, check_error: action.payload};

      case Devise.CLOSE:
        return {...state, loading: false, data: null};
      case Devise.CLOSE_BEGIN:
        return {...state, loading: true, error: null};
      case Devise.CLOSE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Devise.CLOSE_ERROR:
        return {...state, loading: false, error: action.payload};

      case Devise.CONFIRM:
        return {...state, confirm_loading: false, confirm_data: null};
      case Devise.CONFIRM_BEGIN:
        return {...state, confirm_loading: true, confirm_error: null};
      case Devise.CONFIRM_SUCCESS:
        return {...state, confirm_loading: false, confirm_data: action.payload, confirm_success: true};
      case Devise.CONFIRM_ERROR:
        return {...state, confirm_loading: false, confirm_error: action.payload};

      case Devise.PASSWORD:
        return {...state, forgot_loading: false, forgot_data: null};
      case Devise.PASSWORD_BEGIN:
        return {...state, forgot_loading: true, forgot_error: null};
      case Devise.PASSWORD_SUCCESS:
        return {...state, forgot_loading: false, forgot_data: action.payload, forgot_success: true};
      case Devise.PASSWORD_ERROR:
        return {...state, forgot_loading: false, forgot_error: action.payload};

      case Devise.LOADERS:
        return {...state,
          register_loading: false, register_error: null, register_data: null, register_success: null, register_error_data: null,
          forgot_loading: false, forgot_error: null, forgot_data: null, forgot_success: null,
          confirm_loading: false, confirm_error: null, confirm_data: null, confirm_success: null,
          error_data: null, login_loading: false, loading: false, error: null
        };

      case Devise.LOGIN:
        return {...state, loading: false, data: null};
      case Devise.LOGIN_BEGIN:
        return {...state, loading: true, error: null};
      case Devise.LOGIN_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Devise.LOGIN_ERROR:
        return {...state, loading: false, error: action.payload, error_data: action.data_payload};

      case Devise.REGISTER:
        return {...state, register_loading: false, register_data: null};
      case Devise.REGISTER_BEGIN:
        return {...state, register_loading: true, register_error: null};
      case Devise.REGISTER_SUCCESS:
        return {...state, register_loading: false, register_data: action.payload, register_success: true};
      case Devise.REGISTER_ERROR:
        return {...state, register_loading: false, register_error: action.payload, register_error_data: action.payload_data};

      case Devise.SAVE:
        return {...state, loading: false, data: null};
      case Devise.SAVE_BEGIN:
        return {...state, loading: true, save_error: null};
      case Devise.SAVE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Devise.SAVE_ERROR:
        return {...state, loading: false, save_error: action.data_payload};

      case Devise.VALIDATE:
        return {...state, loading: false, validate_data: null};
      case Devise.VALIDATE_BEGIN:
        return {...state, loading: true, validate_error: null};
      case Devise.VALIDATE_SUCCESS:
        return {...state, loading: false, validate_data: action.payload};
      case Devise.VALIDATE_ERROR:
        return {...state, loading: false, validate_error: action.payload, validate_error_data: action.data_payload};

      default:
        return state;
    }
  }
}
