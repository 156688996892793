import SmsPayment from '../../actions/admin/sms_payment';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function sms_payment(state = initial, action) {
  return SmsPayment.state_switch(state, action);
}
