import Appointment from '../../actions/patient/appointment';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function appointment(state = initial, action) {
  return Appointment.state_switch(state, action);
}
