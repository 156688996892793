import Coach from '../../actions/patient/coach';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function coach(state = initial, action) {
  return Coach.state_switch(state, action);
}
