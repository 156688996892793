import server from '../lib/server.js'

export default function rqst_password_post({
  url, begin, succes, error,
  locale, parameters = {}, login, callback
}) {
  return (dispatch) => {
    let h = {};
    if (typeof(login) !== 'undefined' && login !== null) {
      h = {
        "Auth-Token": (login.auth_token || (login.data ? login.data.auth_token : '')),
        "Auth-Email": (login.attributes || login.data.attributes).email
      };
    }

    dispatch({
      type: begin
    });
    return server.post(url, parameters, {
      headers: h
    }).then(_response => {
      var _data = _response.data;
      if (_data.success) {
        dispatch({type: succes, payload: "yes_success"});
        if (callback !== undefined) callback(true);
      } else {
        dispatch({type: error, payload: "no_success"});
        if (callback !== undefined) callback(false);
      }
    }).catch(_error => {
      dispatch({type: error, payload: "http_error"});
    });
  };
}
