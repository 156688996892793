import rqst_post from '../../lib/server_helper.js';

export default class MergeRequest {
  static FETCH_ALL = 'Admin.MergeRequest.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Admin.MergeRequest.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Admin.MergeRequest.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Admin.MergeRequest.FETCH_ALL_ERROR';

  static FETCH_ONE = 'Admin.MergeRequest.FETCH_ONE';
  static FETCH_ONE_BEGIN = 'Admin.MergeRequest.FETCH_ONE_BEGIN';
  static FETCH_ONE_SUCCESS = 'Admin.MergeRequest.FETCH_ONE_SUCCESS';
  static FETCH_ONE_ERROR = 'Admin.MergeRequest.FETCH_ONE_ERROR';

  static SAVE = 'Admin.MergeRequest.SAVE';
  static SAVE_BEGIN = 'Admin.MergeRequest.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Admin.MergeRequest.SAVE_SUCCESS';
  static SAVE_ERROR = 'Admin.MergeRequest.SAVE_ERROR';

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/admin/merge_requests/fetch_all`,
      begin: MergeRequest.FETCH_ALL_BEGIN,
      succes: MergeRequest.FETCH_ALL_SUCCESS,
      error: MergeRequest.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_one = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/admin/merge_requests/fetch_one`,
      begin: MergeRequest.FETCH_ONE_BEGIN,
      succes: MergeRequest.FETCH_ONE_SUCCESS,
      error: MergeRequest.FETCH_ONE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/admin/merge_requests/do_save`,
      begin: MergeRequest.SAVE_BEGIN,
      succes: MergeRequest.SAVE_SUCCESS,
      error: MergeRequest.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case MergeRequest.FETCH_ALL:
        return {...state, loading: false, data: null};
      case MergeRequest.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case MergeRequest.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case MergeRequest.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      case MergeRequest.FETCH_ONE:
        return {...state, one_loading: false, one_data: null};
      case MergeRequest.FETCH_ONE_BEGIN:
        return {...state, one_loading: true, one_error: null};
      case MergeRequest.FETCH_ONE_SUCCESS:
        return {...state, one_loading: false, one_data: action.payload};
      case MergeRequest.FETCH_ONE_ERROR:
        return {...state, one_loading: false, one_error: action.payload};

      case MergeRequest.SAVE:
        return {...state, loading: false, data: null};
      case MergeRequest.SAVE_BEGIN:
        return {...state, loading: true, error: null};
      case MergeRequest.SAVE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case MergeRequest.SAVE_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
