import Location from '../actions/location';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function location(state = initial, action) {
  return Location.state_switch(state, action);
}
