import React, {Component, lazy} from 'react';
// import React, {Component, Suspense, lazy} from 'react';
import {withTranslation} from 'react-i18next';

import CssBaseline from '@material-ui/core/CssBaseline';
import {Route, Switch} from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import {withStyles} from '@material-ui/core/styles';
// import queryString from 'query-string';

import Background from '../images/nutriportalbackground.png';

let Root = null;
let Onboarding = null;

// const urlBase64ToUint8Array = (base64String) => {
//   const padding = "=".repeat((4 - base64String.length % 4) % 4);
//   const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
//
//   const rawData = window.atob(base64);
//   const outputArray = new Uint8Array(rawData.length);
//
//   for (let i = 0; i < rawData.length; ++i) {
//     outputArray[i] = rawData.charCodeAt(i);
//   }
//   return outputArray
// }

// const vapidPublicKey = "";
// const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

// let side = null;

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: (process && (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) || window.matchMedia('(display-mode: standalone)').matches,
      menu_anchor: null,
      login_menu_open: false
    }

    // if (this.props.location && this.props.location.state && this.props.location.state.side) side = this.props.location.state.side;
    //
    // // maakt niets uit voor de eerste keer na login, apparently
    // if (side === null && props.login) {
    //   // this.props.checkLogin({login: this.props.login, locale: this.props.i18n.language});
    //
    //   if (props.login.type === "User::Coach") {
    //     side = "coaches";
    //   } else if (props.login.type === "User::Admin") {
    //     side = "admin";
    //   } else {
    //     side = "patients";
    //   }
    // } else {
    //   if (side === null || queryString.parse(props.location.search, {ignoreQueryPrefix: true}).side !== undefined) {
    //     if (queryString.parse(props.location.search, {ignoreQueryPrefix: true}).side === "admin") {
    //       side = "coaches";
    //     } else if (queryString.parse(props.location.search, {ignoreQueryPrefix: true}).side === "superadmin") {
    //       side = "admin";
    //     } else {
    //       side = "patients";
    //     }
    //   }
    // }
    //
    // // side = "admin"

    // Root = lazy(() => import (`../containers/${side}/Root`));
    Root = lazy(() => import (`../containers/shared/Root`));
    // Onboarding = lazy(() => import (`../containers/${side}/Onboarding`));
    Onboarding = lazy(() => import (`../containers/patients/Onboarding`));

    this.do_push_request = this.do_push_request.bind(this);
  }

  componentDidMount() {
    if (this.props.login) {
      // this.props.checkLogin({login: this.props.login, locale: this.props.i18n.language});
      if (this.props.login.type === "User::Coach") {
        this.props.helpers.practices.fetch_one({
          locale: this.props.i18n.language,
          login: this.props.login,
          parameters: {
            search: ""
          }
        });
      }
    }

    let host = window.location.host;
    let parts = host.split(".");
    let subdomain = "";
// subdomain = "demo";
    if (parts.length >= 3) {
      subdomain = parts[0];
    }
    if (subdomain.length > 0) {
      this.props.helpers.practices.fetch_subdomain({
        locale: this.props.i18n.language,
        callback: () => {},
        parameters: {
          search: "",
          subdomain: subdomain
        }
      });
    }

    this.props.helpers.countries.fetch_all({
      locale: this.props.i18n.language,
      parameters: {
        search: "",
        order: "name ASC"
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (!!this.props.login && (!prevProps.login || this.props.login !== prevProps.login.id)) {
      this.do_push_request();
    }
  }

  do_push_request() {
    // const {t} = this.props;
    // navigator.serviceWorker.ready.then(registration => {
    //   if (!registration.pushManager) {
    //     alert("Push Unsupported")
    //     return
    //   }
    //
    //   registration.pushManager.subscribe({userVisibleOnly: true, applicationServerKey: convertedVapidKey}).then(subscription => this.props.checkPush({login: this.props.login, locale: this.props.i18n.language, subscription, message: t("push_activated")})).catch(err => console.error("Push subscription error: ", err))
    // })
  }

  render() {
    const {classes} = this.props;

    const orig_theme = createMuiTheme({});
    let base = {
      shadows: [
        ...orig_theme.shadows,
        "0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04)"
      ],
      props: {},
      mixins: {},
      typography: {
        fontFamily: [
          'Nunito', 'sans-serif'
        ].join(','),
        h1: {
          fontSize: "1.75rem"
        },
        h5: {
          fontSize: "1.1rem"
        }
      },
      palette: {
        primary: {
          main: '#97BE6B',
          contrastText: '#FFFFFF'
        },
        secondary: {
          main: '#be6b6b',
          contrastText: '#FFFFFF'
        },
        tertiary: {
          main: '#DEBC5A'
        },
        warning: {
          main: '#E69E54',
          fade: 'rgba(230, 157, 84, 0.1)'
        },
        lightgray: "rgb(244,248,247)",
        transparant: "transparent",
        background: {
          default: "#f8f8f8",
          lighter: "#ffffff"
        }
      },
      fancy_bg_img: `url(${Background})`,
      shape: {
        borderRadius: 18
      },
      drawer_width: 240
    }
    let practice = null;
    if (!!this.props.subdomain_practice) practice = this.props.subdomain_practice;
    if (!!this.props.login && !!this.props.login.practice) practice = this.props.login.practice;

    let mah_theme = this.props.theme;
    if (!!practice && !!practice.custom_theme && !!practice.custom_theme.primary_main && !!practice.custom_theme.contrast_main && !!practice.custom_theme.tertiary_main) {
      let pm = practice.custom_theme.primary_main;
      if (pm === 'undefined') pm = '#97BE6B';
      let cm = practice.custom_theme.contrast_main;
      if (cm === 'undefined') cm = '#FFFFFF';
      let tm = practice.custom_theme.tertiary_main;
      if (tm === 'undefined') tm = '#FFFFFF';

      base.palette.primary = {
        main: pm,
        contrastText: cm
      };
      base.palette.tertiary = {
        main: tm
      };
      if (!!practice.custom_theme.onboarding_bg && practice.custom_theme.onboarding_bg.indexOf('missing') < 0) base.fancy_bg_img = `url(${practice.custom_theme.onboarding_bg})`;
      // console.log(base, practice.custom_theme);
      mah_theme = createMuiTheme(base);
    }

    return (<React.Fragment>
      <MuiThemeProvider theme={mah_theme}>
        <CssBaseline></CssBaseline>
        <div className={classes.root}>
          <Switch>
            {!!this.props.login && (<Route component={Root}/>)}
            {!this.props.login && (<Route component={Onboarding}/>)}
          </Switch>

        </div>
      </MuiThemeProvider>
    </React.Fragment>);
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    // minHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
});

export default withTranslation('translations')(withStyles(styles)(Main));
