import Advice from '../../actions/patient/advice';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function advice(state = initial, action) {
  return Advice.state_switch(state, action);
}
