import rqst_post from '../../lib/server_helper.js';

export default class Wake {
  static FETCH_ALL = 'Client.Wake.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Client.Wake.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Client.Wake.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Client.Wake.FETCH_ALL_ERROR';

  static SAVE = 'Client.Wake.SAVE';
  static SAVE_BEGIN = 'Client.Wake.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Client.Wake.SAVE_SUCCESS';
  static SAVE_ERROR = 'Client.Wake.SAVE_ERROR';

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/measure/wakes/fetch_all`,
      begin: Wake.FETCH_ALL_BEGIN,
      succes: Wake.FETCH_ALL_SUCCESS,
      error: Wake.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/measure/wakes/do_save`,
      begin: Wake.SAVE_BEGIN,
      succes: Wake.SAVE_SUCCESS,
      error: Wake.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case Wake.FETCH_ALL:
        return {...state, loading: false, data: null};
      case Wake.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case Wake.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Wake.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      case Wake.SAVE:
        return {...state, loading: false, data: null};
      case Wake.SAVE_BEGIN:
        return {...state, loading: true, error: null};
      case Wake.SAVE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Wake.SAVE_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
