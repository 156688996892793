import AppointmentType from '../../actions/coach/appointment_type';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function appointment_type(state = initial, action) {
  return AppointmentType.state_switch(state, action);
}
