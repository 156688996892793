import Weight from '../../actions/coach/weight';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function weight(state = initial, action) {
  return Weight.state_switch(state, action);
}
