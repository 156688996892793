import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import {I18nextProvider} from 'react-i18next';
// import logger from 'redux-logger'
import {createStore, applyMiddleware, compose} from 'redux';
import {persistStore, persistReducer} from "redux-persist";
import {PersistGate} from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import Main from './containers/Main';
// import './css/icons.css';
import './css/app.scss';
import i18n from './i18n';
import rootReducer from './reducers';
// import { login } from './actions/index';
import {BrowserRouter} from "react-router-dom";
// import registerServiceWorker from './registerServiceWorker';
import {unregister} from './registerServiceWorker';
import 'typeface-roboto';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import HttpsRedirect from 'react-https-redirect';

import { LocalizedMoment } from "./localizationContext";
import { LocalizedNumeral } from "./localizationNumeralContext";
// const colors = {
//   // main: {
//   //   light: '#4789FF',
//   //   main: '#315EAF',
//   //   dark: '#24447F',
//   //   contrastText: '#F4F7FA'
//   // },
//   // secondary: {
//   //   light: '#8EB7FF',
//   //   main: '#6E8EC6',
//   //   dark: '475B7F',
//   //   contrastText: '#F4F7FA',
//   // },
//   // lightermain: "#6586C3",
//   // lines: ["#F4F7FA", "#DFE6EE", "#B0BAC5", "#8091A5", "#667587", "#3F4A56"],
//   // text: ["#313541", "#16181E"],
//   // positive: "#97BE6B",
//   // alert: "#F1AB38",
//   // negative: "#E34C4C",
//   // accents: ["#38A1E0", "#97BE6B"],
//   // grey: "#F2F2F2",
//   // white: "#FFFFFF"
// }
import Background from './images/nutriportalbackground.png';

const orig_theme = createMuiTheme({});

const theme = createMuiTheme({
  shadows: [
    ...orig_theme.shadows,
    "0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04)"
  ],
    props: {
    // MuiPaper: {
    //   elevation: 0,
    //   styles: {
    //     boxShadow: orig_theme.shadows[2],
    //     "&:hover": {
    //       boxShadow: orig_theme.shadows[10]
    //     }
    //   }
    // }
  },
  mixins: {

  },
  typography: {
    fontFamily: [
      'Nunito', 'sans-serif'
    ].join(','),
    h1: {
      fontSize: "1.75rem"
    },
    h5: {
      fontSize: "1.1rem"
    }
  },
  palette: {
    primary: {
      main: '#97BE6B',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#be6b6b',
      contrastText: '#FFFFFF'
    },
    tertiary: {
      main: '#DEBC5A'
    },
    warning: {
      main: '#E69E54',
      fade: 'rgba(230, 157, 84, 0.1)'
    },
    lightgray: "rgb(244,248,247)",
    transparant: "transparent",
    background: {
      default: "#f8f8f8",
      lighter: "#ffffff"
    }
  },
  fancy_bg_img: `url(${Background})`,
  shape: {
    borderRadius: 18
  },
  drawer_width: 240
}


//


// {
//   // palette: {
//   //   primary: colors.main,
//   //   secondary: colors.secondary,
//   //   // error: will use the default color
//   //   colors: colors
//   // },
//   typography: {
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       // "futura-pt", "sans-serif"
//     ].join(','),
//   },
//   // drawer_width: 240,
//   // drawer_width_small: 72
// }
);

const initial_state = {};

const enhancers = [];
// const middleware = [thunk, logger];
const middleware = [thunk];

const persist_config = {
  key: 'root',
  storage,
  blacklist: ["drawer", "register"]
};

const persisted_reducer = persistReducer(persist_config, rootReducer);

const composed_enhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(persisted_reducer, initial_state, composed_enhancers);
const persistor = persistStore(store);
// persistor.purge();

// store.dispatch(login());

ReactDOM.render(<HttpsRedirect><Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <React.Suspense fallback={(<span>Please wait...</span>)}>
    <I18nextProvider i18n={i18n()}>
      <LocalizedMoment i18n={i18n()}>
        <LocalizedNumeral i18n={i18n()}>
          <BrowserRouter>
            <MuiThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Main theme={theme}></Main>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </BrowserRouter>
        </LocalizedNumeral>
      </LocalizedMoment>
    </I18nextProvider>
    </React.Suspense>
  </PersistGate>
</Provider></HttpsRedirect>, document.getElementById('root'));

// registerServiceWorker();
unregister();
