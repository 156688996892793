import rqst_post from '../../lib/server_helper.js';

export default class EmailTemplate {
  static DESTROY = 'Coach.EmailTemplate.DESTROY';
  static DESTROY_BEGIN = 'Coach.EmailTemplate.DESTROY_BEGIN';
  static DESTROY_SUCCESS = 'Coach.EmailTemplate.DESTROY_SUCCESS';
  static DESTROY_ERROR = 'Coach.EmailTemplate.DESTROY_ERROR';

  static FETCH_ALL = 'Coach.EmailTemplate.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Coach.EmailTemplate.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Coach.EmailTemplate.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Coach.EmailTemplate.FETCH_ALL_ERROR';

  static FETCH_DEFAULT = 'Coach.EmailTemplate.FETCH_DEFAULT';
  static FETCH_DEFAULT_BEGIN = 'Coach.EmailTemplate.FETCH_DEFAULT_BEGIN';
  static FETCH_DEFAULT_SUCCESS = 'Coach.EmailTemplate.FETCH_DEFAULT_SUCCESS';
  static FETCH_DEFAULT_ERROR = 'Coach.EmailTemplate.FETCH_DEFAULT_ERROR';

  static SAVE = 'Coach.EmailTemplate.SAVE';
  static SAVE_BEGIN = 'Coach.EmailTemplate.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Coach.EmailTemplate.SAVE_SUCCESS';
  static SAVE_ERROR = 'Coach.EmailTemplate.SAVE_ERROR';

  static destroy = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/email_templates/do_destroy`,
      begin: EmailTemplate.DESTROY_BEGIN,
      succes: EmailTemplate.DESTROY_SUCCESS,
      error: EmailTemplate.DESTROY_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/email_templates/fetch_all`,
      begin: EmailTemplate.FETCH_ALL_BEGIN,
      succes: EmailTemplate.FETCH_ALL_SUCCESS,
      error: EmailTemplate.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_default = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/email_templates/fetch_default`,
      begin: EmailTemplate.FETCH_ALL_BEGIN,
      succes: EmailTemplate.FETCH_ALL_SUCCESS,
      error: EmailTemplate.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/email_templates/do_save`,
      begin: EmailTemplate.SAVE_BEGIN,
      succes: EmailTemplate.SAVE_SUCCESS,
      error: EmailTemplate.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case EmailTemplate.DESTROY:
        return {...state, loading: false, data: null};
      case EmailTemplate.DESTROY_BEGIN:
        return {...state, loading: true, error: null};
      case EmailTemplate.DESTROY_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case EmailTemplate.DESTROY_ERROR:
        return {...state, loading: false, error: action.payload};

      case EmailTemplate.FETCH_ALL:
        return {...state, loading: false, data: null};
      case EmailTemplate.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case EmailTemplate.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case EmailTemplate.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      case EmailTemplate.FETCH_DEFAULT:
        return {...state, loading: false, default_data: null};
      case EmailTemplate.FETCH_DEFAULT_BEGIN:
        return {...state, loading: true, default_error: null};
      case EmailTemplate.FETCH_DEFAULT_SUCCESS:
        return {...state, loading: false, default_data: action.payload};
      case EmailTemplate.FETCH_DEFAULT_ERROR:
        return {...state, loading: false, default_error: action.payload};

      case EmailTemplate.SAVE:
        return {...state, loading: false, data: null};
      case EmailTemplate.SAVE_BEGIN:
        return {...state, loading: true, error: null};
      case EmailTemplate.SAVE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case EmailTemplate.SAVE_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
