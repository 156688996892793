import rqst_post from '../../lib/server_helper.js';

export default class Workshop {
  static DESTROY = 'Coach.Workshop.DESTROY';
  static DESTROY_BEGIN = 'Coach.Workshop.DESTROY_BEGIN';
  static DESTROY_SUCCESS = 'Coach.Workshop.DESTROY_SUCCESS';
  static DESTROY_ERROR = 'Coach.Workshop.DESTROY_ERROR';

  static FETCH_ALL = 'Coach.Workshop.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Coach.Workshop.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Coach.Workshop.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Coach.Workshop.FETCH_ALL_ERROR';

  static SAVE = 'Coach.Workshop.SAVE';
  static SAVE_BEGIN = 'Coach.Workshop.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Coach.Workshop.SAVE_SUCCESS';
  static SAVE_ERROR = 'Coach.Workshop.SAVE_ERROR';

  static SAVE_REC = 'Coach.Workshop.SAVE_REC';
  static SAVE_REC_BEGIN = 'Coach.Workshop.SAVE_REC_BEGIN';
  static SAVE_REC_SUCCESS = 'Coach.Workshop.SAVE_REC_SUCCESS';
  static SAVE_REC_ERROR = 'Coach.Workshop.SAVE_REC_ERROR';

  static SUBSCRIBE = 'Coach.Workshop.SUBSCRIBE';
  static SUBSCRIBE_BEGIN = 'Coach.Workshop.SUBSCRIBE_BEGIN';
  static SUBSCRIBE_SUCCESS = 'Coach.Workshop.SUBSCRIBE_SUCCESS';
  static SUBSCRIBE_ERROR = 'Coach.Workshop.SUBSCRIBE_ERROR';

  static UNSUBSCRIBE = 'Coach.Workshop.UNSUBSCRIBE';
  static UNSUBSCRIBE_BEGIN = 'Coach.Workshop.UNSUBSCRIBE_BEGIN';
  static UNSUBSCRIBE_SUCCESS = 'Coach.Workshop.UNSUBSCRIBE_SUCCESS';
  static UNSUBSCRIBE_ERROR = 'Coach.Workshop.UNSUBSCRIBE_ERROR';

  static destroy = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_workshop/workshops/do_destroy`,
      begin: Workshop.DESTROY_BEGIN,
      succes: Workshop.DESTROY_SUCCESS,
      error: Workshop.DESTROY_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_workshop/workshops/fetch_all`,
      begin: Workshop.FETCH_ALL_BEGIN,
      succes: Workshop.FETCH_ALL_SUCCESS,
      error: Workshop.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_workshop/workshops/do_save`,
      begin: Workshop.SAVE_BEGIN,
      succes: Workshop.SAVE_SUCCESS,
      error: Workshop.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static save_recurrence = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_workshop/workshops/save_recurrence`,
      begin: Workshop.SAVE_REC_BEGIN,
      succes: Workshop.SAVE_REC_SUCCESS,
      error: Workshop.SAVE_REC_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static subscribe = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_workshop/workshops/subscribe`,
      begin: Workshop.SUBSCRIBE_BEGIN,
      succes: Workshop.SUBSCRIBE_SUCCESS,
      error: Workshop.SUBSCRIBE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static unsubscribe = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/coach/nutri_workshop/workshops/unsubscribe`,
      begin: Workshop.UNSUBSCRIBE_BEGIN,
      succes: Workshop.UNSUBSCRIBE_SUCCESS,
      error: Workshop.UNSUBSCRIBE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case Workshop.DESTROY:
        return {...state, loading: false, data: null};
      case Workshop.DESTROY_BEGIN:
        return {...state, loading: true, error: null};
      case Workshop.DESTROY_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Workshop.DESTROY_ERROR:
        return {...state, loading: false, error: action.payload};

      case Workshop.FETCH_ALL:
        return {...state, loading: false, data: null};
      case Workshop.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case Workshop.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Workshop.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      case Workshop.SAVE:
        return {...state, loading: false, data: null};
      case Workshop.SAVE_BEGIN:
        return {...state, loading: true, error: null};
      case Workshop.SAVE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Workshop.SAVE_ERROR:
        return {...state, loading: false, error: action.payload};

      case Workshop.SAVE_REC:
        return {...state, loading: false, save_rec_data: null};
      case Workshop.SAVE_REC_BEGIN:
        return {...state, loading: true, save_rec_error: null};
      case Workshop.SAVE_REC_SUCCESS:
        return {...state, loading: false, save_rec_data: action.payload};
      case Workshop.SAVE_REC_ERROR:
        return {...state, loading: false, save_rec_error: action.payload};

      case Workshop.SUBSCRIBE:
        return {...state, loading: false, data: null};
      case Workshop.SUBSCRIBE_BEGIN:
        return {...state, loading: true, error: null};
      case Workshop.SUBSCRIBE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Workshop.SUBSCRIBE_ERROR:
        return {...state, loading: false, error: action.payload};

      case Workshop.UNSUBSCRIBE:
        return {...state, loading: false, data: null};
      case Workshop.UNSUBSCRIBE_BEGIN:
        return {...state, loading: true, error: null};
      case Workshop.UNSUBSCRIBE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Workshop.UNSUBSCRIBE_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
