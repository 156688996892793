import rqst_post from '../../lib/server_helper.js';

export default class SmsPayment {
  static FETCH_ALL = 'Admin.SmsPayment.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Admin.SmsPayment.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Admin.SmsPayment.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Admin.SmsPayment.FETCH_ALL_ERROR';

  static GET_STATS = 'Admin.SmsPayment.GET_STATS';
  static GET_STATS_BEGIN = 'Admin.SmsPayment.GET_STATS_BEGIN';
  static GET_STATS_SUCCESS = 'Admin.SmsPayment.GET_STATS_SUCCESS';
  static GET_STATS_ERROR = 'Admin.SmsPayment.GET_STATS_ERROR';

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/admin/sms_payments/fetch_all`,
      begin: SmsPayment.FETCH_ALL_BEGIN,
      succes: SmsPayment.FETCH_ALL_SUCCESS,
      error: SmsPayment.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  static get_stats = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/admin/sms_payments/get_stats`,
      begin: SmsPayment.GET_STATS_BEGIN,
      succes: SmsPayment.GET_STATS_SUCCESS,
      error: SmsPayment.GET_STATS_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case SmsPayment.FETCH_ALL:
        return {...state, loading: false, data: null};
      case SmsPayment.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case SmsPayment.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case SmsPayment.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      case SmsPayment.GET_STATS:
        return {...state, stat_loading: false, stat_data: null};
      case SmsPayment.GET_STATS_BEGIN:
        return {...state, stat_loading: true, stat_error: null};
      case SmsPayment.GET_STATS_SUCCESS:
        return {...state, stat_loading: false, stat_data: action.payload};
      case SmsPayment.GET_STATS_ERROR:
        return {...state, stat_loading: false, stat_error: action.payload};

      default:
        return state;
    }
  }
}
