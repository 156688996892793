import EhealthMail from '../../actions/coach/ehealth_mail';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function ehealth_mail(state = initial, action) {
  return EhealthMail.state_switch(state, action);
}
