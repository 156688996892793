import rqst_post from '../../lib/server_helper.js';

export default class AppointmentType {
  static FETCH_ALL = 'Client.AppointmentType.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Client.AppointmentType.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Client.AppointmentType.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Client.AppointmentType.FETCH_ALL_ERROR';

  static fetch_all = ({
    locale, parameters = {}, login, callback
  }) => {
    return rqst_post({
      url: `/${locale}/client/nutri_calendar/types/do_find`,
      begin: AppointmentType.FETCH_ALL_BEGIN,
      succes: AppointmentType.FETCH_ALL_SUCCESS,
      error: AppointmentType.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case AppointmentType.FETCH_ALL:
        return {...state, loading: false, data: null};
      case AppointmentType.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case AppointmentType.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case AppointmentType.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
