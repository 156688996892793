import User from '../../actions/coach/user';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function user(state = initial, action) {
  return User.state_switch(state, action);
}
