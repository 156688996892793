import EatMoment from '../../actions/patient/eat_moment';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function eat_moment(state = initial, action) {
  return EatMoment.state_switch(state, action);
}
