import Diary from '../../actions/coach/diary';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function diary(state = initial, action) {
  return Diary.state_switch(state, action);
}
