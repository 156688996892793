import CoachSkill from '../../actions/coach/coach_skill';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function coach_skill(state = initial, action) {
  return CoachSkill.state_switch(state, action);
}
