import Agenda from '../../actions/coach/agenda';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function agenda(state = initial, action) {
  return Agenda.state_switch(state, action);
}
