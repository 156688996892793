import DayNote from '../../actions/coach/day_note';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function day_note(state = initial, action) {
  return DayNote.state_switch(state, action);
}
