import axios from 'axios';

const KEY = "Dcps4WdQ0MYQw0R3";
const api_url = 'https://api.nutriportal.eu';
// const api_url = 'https://api-st.nutriportal.eu';

const instance = axios.create({
  baseURL: api_url,
  timeout: 60000,
  headers: {
    'X-KANGA-Key': KEY
  }
});

export default instance;
