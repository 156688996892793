import Consent from '../../actions/patient/consent';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function consent(state = initial, action) {
  return Consent.state_switch(state, action);
}
