import CoachStats from '../../actions/coach/stats';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function coach_stats(state = initial, action) {
  return CoachStats.state_switch(state, action);
}
