import Log from '../../actions/coach/log';

const initial = {
  data: [],
  loading: false,
  error: null
};

export default function log(state = initial, action) {
  return Log.state_switch(state, action);
}
